import requests from "./request";
// import mockRequests from "./mockAjax";
export const reqAddOrUpdateShopCart = (skuId, skuNum) => {
   return requests({ url: `/cart/addToCart/${skuId}/${skuNum}`, method: 'post' })
}

//获取个人中心数据 /api/order/auth/{page}/{limit}
export const articleDetail = (data) => requests({ url: `/article/detail`, method: 'post', data: data })//各个文章信息

export const carouselList = (data) => requests({ url: `/Homebanner/lists`, method: 'get', data: data })//获取轮播图信息

export const menuList = (data) => requests({ url: `/Articlecate/lists`, method: 'get', data: data })//获取导航栏 头部信息 首页 关于我们 产品（有二级信息3个系统的简介）案例

export const downList = (data) => requests({ url: `/Articlecate/listsporder`, method: 'post', data: data })//更多优秀案例的信息

export const caseList = (data) => requests({ url: `/Article/cases`, method: 'post', data: data })//显示下载外链的案例信息
export const case_detail = (data) => requests({ url: `/Article/cases_detail`, method: 'get', params: data })
// export const case_detail1 = (id) => requests({ url: `/Article/cases_detail?id=${id}`, method: 'get',  })
export const connectInfo = (data) => requests({ url: `/Contact/add`, method: 'post', data: data })
export const TopBottomInfo = () => requests({ url: `/Settings/lists`, method: 'get',  })