<template>
    <!-- 底部 -->
    <div class="bottom">
        <!-- 底部版心 -->
        <div class="content">
            <!-- 上边文字 -->
            <div class="top">
                <!--   左边文字  -->
                <div class="intro">
                    <!-- 三个区域 -->
                    <div class="introText1" style=" cursor: pointer;" @click="goIndex">{{list[8].value}}</div>
                    <div class="introText2">
                        <div class="textTop"><a href="" style="text-decoration: none;">客服QQ:&nbsp;&nbsp;&nbsp;&nbsp;{{list[5].value}}</a></div>
                        <div class="textBottom"><a href="" style="text-decoration: none;">合作邮箱:&nbsp;&nbsp;{{list[4].value}}</a></div>
                    </div>
                    <div class="introText2">
                        <div class="textTop"><a href="" style="text-decoration: none;">客服电话:{{ list[3].value }}</a></div>
                        <div class="textBottom"><a href="" style="text-decoration: none;">联系电话:{{list[2].value}}</a></div>
                    </div>
                </div>
                <!-- 右边二维码 -->
                <div class="ErCode">
                    <div class="one">
                        <img :src="`https://zydadmin.sdsj88.cn/${list[0].value}`" alt="">
                        <div class="ErCodeText">微信公众号</div>
                    </div>

                    <!-- <div class="one">
                        <img :src="`https://zydadmin.sdsj88.cn/${list[0].value}`" alt="">
                        <div class="ErCodeText">微信公众号</div>
                    </div> -->
                </div>
            </div>
            <!-- 下边文字 -->
            <div class="Downward">
                <div class="DownText">
                    <span><a href="https://beian.miit.gov.cn/#/Integrated/index">粤ICP备2024318488号</a></span>
                    <span>公司地址：{{list[1].value}}</span>
                    <a href="">服务协议</a><span class="line">|</span>
                    <a href="">隐私政策</a><span class="line">|</span>
                    <a href="">开源软件信息</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'bottom',
    data() {
        return {

        }
    },
    props:['list'],
    methods:{
            //点击去首页
    goIndex(){
      console.log('去首页');
      if(this.$route.path==='/index'){
       return
      }else if(this.$route.path==='/goConnect'|| this.$route.path==='/detail'){
        this.$router.push({path:'/index'})
      }

      
    }
    },
}
</script>
<style scoped lang="scss">
// 底部
.bottom {
    width: 100%;
    height: 210px;
    // border: 1px solid red;
    background-color: white;

    //底部版心
    .content {
        margin: 0 auto;
        width: 1200px;
        height: 100%;
        // border: 1px solid red;

        // 上边文字
        .top {
            display: flex;
            justify-content: space-between;
            width: 100%;
            height: 135px;
            border-bottom: 1px solid #ebf0f8;

            //    左边文字 
            .intro {
                width: 800px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .introText1 {
                    font-size: 20px;
                    color: #f56027;
                    font-weight: bold;
                }

                .introText2 {
                    font-size: 15px;
                    color: #606464;
                    font-weight: bold;
                }
            }

            //   右边二维码
            .ErCode {
                display: flex;
                width: 200px;
                align-items: center;
                justify-content: space-around;

            }
        }

        // 下边文字
        .Downward {
            height: 80px;
            display: flex;
            justify-content: center;
            align-items: center;

            .DownText {
                a {
                    padding: 0 5px;
                    color: #606464;
                    text-decoration: none;
                    font-weight: bold;
                    .line{

                    }
                }
            }
        }
    }
}
</style>